import {
  Avatar,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import {
  createConversation,
  suggestNewConversation,
} from '@/controllers/chat.controller';
import useSmartToast from '@/hooks/SmartToast';

export default function NewConversationModal({ refresh, isOpen, onClose }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const toast = useSmartToast();

  const fetchUsers = async () => {
    setLoading(true);

    try {
      const users = await suggestNewConversation('direct');
      setUsers(users);
    } catch (error) {
      toast.error(error.message, 'Failed to fetch users');
    }

    setLoading(false);
  };

  const handleCreate = async () => {
    setCreateLoading(true);

    try {
      await createConversation('direct', selectedUser.id);
      refresh();
      onClose();
    } catch (error) {
      toast.error(error.message, 'Failed to create conversation');
    }

    setCreateLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalCloseButton />

      <ModalContent>
        <ModalHeader>New Conversation</ModalHeader>

        <ModalBody pt='0' mt='0'>
          <Stack>
            <InputGroup size='sm'>
              <InputLeftElement>
                <FiSearch />
              </InputLeftElement>
              <Input
                fontSize='sm'
                variant='filled'
                placeholder='Search users'
              />
            </InputGroup>

            <Stack h='128' overflowY='auto' gap='0'>
              {users.map(user => (
                <HStack
                  key={user.id}
                  cursor='pointer'
                  px='4'
                  py='2'
                  borderBottom='1px solid'
                  borderColor='gray.200'
                  transition='all 0.2s ease-in-out'
                  onClick={() => setSelectedUser(user)}
                  bg={
                    selectedUser?.id === user.id ? 'orange.50' : 'transparent'
                  }
                  color={
                    selectedUser?.id === user.id ? 'orange.500' : 'inherit'
                  }
                  _hover={{
                    bg: selectedUser?.id === user.id ? 'orange.50' : 'gray.100',
                  }}
                >
                  <Avatar h='36px' w='36px' size='sm' name={user.name} />

                  <Stack gap='0' mx='1'>
                    <Text fontSize='sm' fontWeight='medium'>
                      {user.name}
                    </Text>
                    <Text
                      fontSize='sm'
                      color={
                        selectedUser?.id === user.id ? 'orange.500' : 'gray.500'
                      }
                    >
                      {user.role.name}
                    </Text>
                  </Stack>
                </HStack>
              ))}
            </Stack>
          </Stack>

          <ModalFooter>
            <Button
              variant='procura'
              isLoading={createLoading}
              isDisabled={!selectedUser}
              onClick={() => {
                handleCreate();
              }}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
