import {
  Avatar,
  Box,
  HStack,
  IconButton,
  Stack,
  chakra,
  shouldForwardProp,
} from '@chakra-ui/react';
import { AnimatePresence, isValidMotionProp, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { FiChevronLeft, FiMessageSquare, FiX } from 'react-icons/fi';
import { useAuth } from '@/hooks/AuthContext';
import { useCart } from '@/hooks/CartContext';
import { useChat } from '@/hooks/ChatContext';
import useSmartToast from '@/hooks/SmartToast';
import ChatConversationsList from './ChatConversationsList';
import ChatMessagesView from './ChatMessagesView';

const MotionStack = chakra(motion.div, {
  shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export default function ChatWindow() {
  const { user } = useAuth();
  const { isCartOpen } = useCart();
  const router = useRouter();

  if (user.type !== 'client' || isCartOpen) {
    return null;
  }

  if (router.pathname.includes('/me/products')) {
    return null;
  }

  return <ChatWindowInner />;
}

function ChatWindowInner() {
  const { setIsCartOpen, isCartOpen } = useCart();
  const [isChatOpen, setIsChatOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  const chatController = useChat();
  const { user } = useAuth();
  const router = useRouter();
  const toast = useSmartToast();
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (isChatOpen) {
      setIsCartOpen(false);
    }
  }, [isChatOpen]);

  useEffect(() => {
    if (!router.isReady) return;
    // fetchMessages();
    chatController.connect();
    return () => {
      chatController.disconnect();
    };
  }, [router.isReady]);

  useEffect(() => {
    setIsChatOpen(false);
  }, [router.pathname]);

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      const checkShouldIgnore = (elem: HTMLElement) => {
        if (elem.classList.contains('cart-ignore')) {
          return true;
        }

        if (elem.parentElement) {
          return checkShouldIgnore(elem.parentElement);
        }

        return false;
      };

      if (
        e.target &&
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target) &&
        !checkShouldIgnore(e.target as HTMLElement)
      ) {
        setIsChatOpen(false);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const currentChatParticipants =
    chatController.currentConversation?.participants?.filter(
      x => x.id !== user.id,
    ) || [];
  const currentChatParticipant = currentChatParticipants?.length
    ? currentChatParticipants[0]
    : 0;

  return (
    <>
      <AnimatePresence>
        {!isChatOpen && (
          <MotionStack
            initial={{ opacity: 0, y: 500 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 500 }}
            transition={{ duration: 0.2, ease: 'easeInOut' } as any}
            position='fixed'
            bottom='0'
            right='4'
            gap='0'
            zIndex={1000000}
            ref={wrapperRef}
            w='96'
            bg='white'
            boxShadow='lg'
            roundedTop='2xl'
            overflow='hidden'
            border='1px solid'
            borderColor='gray.200'
            cursor='pointer'
            onClick={() => setIsChatOpen(true)}
          >
            <Stack w='96'>
              <HStack
                justifyContent='space-between'
                p='3'
                borderBottomWidth='1px'
                borderBottomColor='gray.200'
              >
                <HStack pl='3'>
                  <FiMessageSquare size='20' color='black' />
                  <Stack gap='0'>
                    <Box fontWeight='semibold' fontSize='md' ml='2'>
                      Chat
                    </Box>
                  </Stack>
                </HStack>
                <IconButton
                  aria-label='Open chat'
                  icon={<FiMessageSquare />}
                  onClick={() => setIsChatOpen(true)}
                  size='md'
                  colorScheme='gray'
                  variant='ghost'
                  color='gray.500'
                />
              </HStack>
            </Stack>
          </MotionStack>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isChatOpen && (
          <MotionStack
            initial={{ opacity: 0, y: 500 }}
            animate={{ opacity: 1, y: 0 }}
            zIndex={1000000}
            exit={{ opacity: 0, y: 500 }}
            transition={{ duration: 0.2, ease: 'easeInOut' } as any}
            position='fixed'
            bottom='0'
            right='4'
            gap='0'
            ref={wrapperRef}
            w='96'
            h='lg'
            bg='white'
            boxShadow='lg'
            roundedTop='2xl'
            overflow='hidden'
            border='1px solid'
            borderColor='gray.200'
          >
            <Stack w='96' h='lg'>
              <HStack
                justifyContent='space-between'
                p='3'
                borderBottomWidth='1px'
                borderBottomColor='gray.200'
              >
                {chatController.currentConversation ? (
                  chatController.currentConversation.type === 'direct' ? (
                    <HStack pl='3'>
                      <IconButton
                        aria-label='Back'
                        icon={<FiChevronLeft />}
                        onClick={() =>
                          chatController.setCurrentConversation(null)
                        }
                        size='md'
                        mx='-4'
                        colorScheme='gray'
                        variant='ghost'
                        color='gray.500'
                      />

                      <Avatar name={currentChatParticipant?.name} size='sm' />

                      <Stack gap='0' ml='2'>
                        <Box fontWeight='semibold' fontSize='sm'>
                          {currentChatParticipant?.name}
                        </Box>
                        <Box fontSize='xs' color='gray.500'>
                          {currentChatParticipant?.email}
                        </Box>
                      </Stack>
                    </HStack>
                  ) : (
                    <HStack pl='3'>
                      <IconButton
                        aria-label='Back'
                        icon={<FiChevronLeft />}
                        onClick={() =>
                          chatController.setCurrentConversation(null)
                        }
                        size='md'
                        mx='-4'
                        colorScheme='gray'
                        variant='ghost'
                        color='gray.500'
                      />

                      <Avatar
                        name={
                          chatController.currentConversation.participants[0]
                            .name
                        }
                        size='sm'
                      />

                      <Stack gap='0' ml='2'>
                        <Box fontWeight='semibold' fontSize='sm'>
                          {chatController.currentConversation.order.orderNumber}
                        </Box>
                        <Box fontSize='xs' color='gray.500'>
                          {chatController.currentConversation.order.status}
                        </Box>
                      </Stack>
                    </HStack>
                  )
                ) : (
                  <HStack pl='3'>
                    <FiMessageSquare size='20' color='black' />
                    <Box fontWeight='semibold' fontSize='md'>
                      Chat
                    </Box>
                  </HStack>
                )}
                <IconButton
                  aria-label='Close chat'
                  icon={<FiX />}
                  onClick={() => {
                    setIsChatOpen(false);
                    chatController.setCurrentConversation(null);
                  }}
                  size='md'
                  colorScheme='gray'
                  variant='ghost'
                  color='gray.500'
                />
              </HStack>

              {!chatController.currentConversation && <ChatConversationsList />}

              {chatController.currentConversation && <ChatMessagesView />}
            </Stack>
          </MotionStack>
        )}
      </AnimatePresence>
    </>
  );
}
