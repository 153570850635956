import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  Heading,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { FiHome } from 'react-icons/fi';
import { usePageTitle } from '../../hooks/PageTitleHook';

export function PageTitle() {
  const pageTitle = usePageTitle();
  const isMobile = useMediaQuery('(max-width: 1080px)')[0];

  return (
    <Stack
      position={isMobile ? 'relative' : 'absolute'}
      top={isMobile ? '20' : '0'}
      color='white'
      h={isMobile ? '16' : '24'}
      justifyContent='center'
      zIndex='50'
    >
      {pageTitle.breadcrumbs && pageTitle.breadcrumbs.length > 0 && (
        <Breadcrumb
          spacing='8px'
          fontSize='sm'
          separator={
            <Box color='gray.200' fontSize='14px'>
              /
            </Box>
          }
        >
          <BreadcrumbItem>
            <FiHome />
          </BreadcrumbItem>
          {pageTitle.breadcrumbs.map((breadcrumb, index) => (
            <BreadcrumbItem key={index} fontWeight='medium'>
              <BreadcrumbLink href={breadcrumb.href}>
                {breadcrumb.label}
              </BreadcrumbLink>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      )}
      <Heading
        as='h1'
        size={isMobile ? 'lg' : 'md'}
        fontWeight='bold'
        color='white'
      >
        <HStack>
          {pageTitle.icon}
          <Text>{pageTitle.title}</Text>
        </HStack>
      </Heading>
    </Stack>
  );
}
