import { createContext, useContext, useEffect, useState } from 'react';
import axiosClient from '../utils/axios';
import { useAuth } from './AuthContext';

const CartContext = createContext<CartContextData>(null);

export const useCart = (): CartContextData => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState<CartItemType[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<any>(null);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [quoteURL, setQuoteURL] = useState('');
  const { user, loginStatus } = useAuth();
  const [isCartUpdating, setIsCartUpdating] = useState(false);

  const refetchCart = async () => {
    const { data } = await axiosClient.get('/client/cart/list', {
      params: {
        vendorId: user.accountType === 'SAAS' ? selectedVendor?.id : undefined,
      },
    });
    setCart(data.cartItems);
    setQuoteURL(data.quoteUrl);
  };

  const updateCartItem = async (productId, quantity) => {
    if (isCartUpdating) return;

    setIsCartUpdating(true);

    try {
      const res = await axiosClient.patch(`/client/cart/update`, {
        productId,
        quantity,
      });
      const data = res.data;
      setCart(data.cart);
    } catch (error) {
      console.error(error);
    }

    setIsCartUpdating(false);
  };

  const replaceCart = async items => {
    if (isCartUpdating) return;

    setIsCartUpdating(true);

    try {
      const res = await axiosClient.post(`/client/cart/replace`, {
        items,
      });
      const data = res.data;
      setCart(data.cart);
    } catch (error) {
      console.error(error);
    }

    setIsCartUpdating(false);
  };

  const addItemToCart = async (productId: string) => {
    await updateCartItem(productId, 1);
  };

  const removeItemFromCart = async (productId: string) => {
    await updateCartItem(productId, 0);
  };

  const getItemQuantity = (productId: string) => {
    const item = cart.find(item => item.product.id === productId);
    return item ? item.quantity : 0;
  };

  useEffect(() => {
    if (loginStatus === 'LOGGED_IN' && user && user.type === 'client') {
      refetchCart();
    }
  }, [user, loginStatus, selectedVendor]);

  return (
    <CartContext.Provider
      value={{
        cart,
        quoteURL,
        setQuoteURL,
        refetchCart,
        addItemToCart,
        removeItemFromCart,
        updateCartItem,
        replaceCart,
        getItemQuantity,
        setIsCartOpen,
        isCartOpen,
        isCartUpdating,
        selectedVendor,
        setSelectedVendor,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

interface CartContextData {
  cart: CartItemType[];
  quoteURL: string;
  setQuoteURL: (url: string) => void;
  refetchCart: () => void;
  addItemToCart: (product: any) => void;
  removeItemFromCart: (product: any) => void;
  updateCartItem: (productId: string, quantity: number) => void;
  getItemQuantity: (productId: string) => number;
  setIsCartOpen: (value: boolean | ((current: boolean) => boolean)) => void;
  replaceCart: (items: any) => void;
  isCartOpen: boolean;
  isCartUpdating: boolean;
  selectedVendor: any;
  setSelectedVendor: (vendor: any) => void;
}

interface CartItemType {
  product: any;
  quantity: number;
}
