import {
  Box,
  HStack,
  Image,
  Stack,
  useDisclosure,
  useMediaQuery,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { ReactNode, useEffect } from 'react';
import { useAuth } from '@/hooks/AuthContext';
import { isDemoEnv } from '@/utils/utils';
import ChatWindow from '../chat/ChatWindow';
import FloatingCart from '../clients/cart/FloatingCart';
import FullPageLoader from './FullPageLoader';
import MobileNav from './MobileNav';
import { PageTitle } from './PageTitle';
import Sidebar from './Sidebar';

export default function Layout({
  children,
  layoutParams,
}: {
  children: ReactNode;
  layoutParams?: LayoutParams;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { loginStatus, user } = useAuth();
  const router = useRouter();
  const isMobile = useMediaQuery('(max-width: 1080px)')[0];

  useEffect(() => {
    if (loginStatus === 'LOGGED_OUT') {
      router.push('/login');
    } else if (
      loginStatus === 'LOGGED_IN' &&
      user.type.toLowerCase() === 'vendor' &&
      !user?.profileCompleted
    ) {
      router.push('/vendors/profile/complete');
    }
  }, [loginStatus, user]);

  if (loginStatus === 'UNKNOWN' || loginStatus === 'LOGGED_OUT') {
    return <FullPageLoader />;
  }

  return (
    <Stack gap={0} minH='100vh'>
      {isDemoEnv() && (
        <HStack
          position='fixed'
          w='full'
          h='8'
          bg='orange.500'
          color='white'
          flexShrink='0'
          px='16'
          justifyContent='center'
          textAlign='center'
          alignItems='center'
          zIndex='100000'
        >
          <Text fontSize='sm' fontWeight='medium'>
            This is a demo environment. You will not be allowed to make any
            changes.
          </Text>
        </HStack>
      )}

      <Box
        w='full'
        minH='100vh'
        bg={
          layoutParams.useNewLayout
            ? '#f5f8fe'
            : layoutParams.bgColor
              ? layoutParams.bgColor
              : 'white'
        }
      >
        {layoutParams.useNewLayout && (
          <>
            <Sidebar isOpen={isOpen} onOpen={onOpen} onClose={onClose} />

            <MobileNav
              onOpen={onOpen}
              useNewLayout={layoutParams.useNewLayout}
            />
          </>
        )}

        <Box ml={layoutParams.useNewLayout ? { base: 0, md: 60 } : {}}>
          {layoutParams.useNewLayout && (
            <>
              <Box
                w={{ base: 'full', md: 'calc(100% - 15rem)' }}
                position='absolute'
                h={isMobile ? '52' : '40'}
                pointerEvents='none'
                zIndex='0'
              >
                <Image
                  w='full'
                  h='full'
                  backgroundImage={`url(/images/hexagon-blue.svg)`}
                  backgroundSize='cover'
                  backgroundPosition='center'
                />
              </Box>

              <Box px={{ base: '4', md: '8' }} position='relative'>
                <PageTitle />
              </Box>

              <Box
                position='relative'
                {...(layoutParams.removePadding
                  ? {}
                  : { px: { base: '4', md: '8' }, pt: '24', pb: '16' })}
              >
                {children}
              </Box>
            </>
          )}

          {!layoutParams.useNewLayout && (
            <Box
              {...(layoutParams.removePadding
                ? {}
                : { px: { base: '4', md: '8' }, pt: '24', pb: '16' })}
            >
              {children}
            </Box>
          )}
        </Box>

        <FloatingCart />
        <ChatWindow />
      </Box>
    </Stack>
  );
}

interface LayoutParams {
  removePadding?: boolean;
  useNewLayout?: boolean;
  bgColor?: string;
}
