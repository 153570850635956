import axiosClient from '../utils/axios';

export const listQuotations = async params => {
  const res = await axiosClient.get('/quotation/list', {
    params,
  });
  return res.data;
};

export const listMyQuotations = async (params: any) => {
  const res = await axiosClient.get('/quotation/list/me', {
    params,
  });
  return res.data;
};

export const getQuotation = async id => {
  const res = await axiosClient.get(`/quotation/view/${id}`);
  return res.data;
};

export const createQuotation = async (quotation, imageFiles) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(quotation));
  imageFiles.forEach((imageFile, i) => {
    formData.append('image' + i, imageFile);
  });
  const res = await axiosClient.post('/quotation/create', formData);
  return res.data;
};

export const deleteQuotation = async id => {
  const res = await axiosClient.delete(`/quotation/delete/${id}`);
  return res.data;
};

export const updateQuotation = async (quotation, imageFiles) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(quotation));
  imageFiles.forEach((imageFile, i) => {
    formData.append('image' + i, imageFile);
  });
  const res = await axiosClient.post(`/quotation/update`, formData);
  return res.data;
};

export const getProductsInfo = async selectedProducts => {
  const productsInfo = await axiosClient.get('/catalog/to-quote', {
    params: {
      codes: JSON.stringify(selectedProducts.map(s => s.busyAlias)),
    },
  });
  return productsInfo.data;
};

export const getQuotationForCart = async () => {
  const { data } = await axiosClient.get('/quotation/create-from-cart');
  return data;
};
