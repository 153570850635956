import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  ModalFooter,
  HStack,
  Text,
  Box,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FiCheckCircle } from 'react-icons/fi';
import { listVendors } from '../../../controllers/vendor.controller';
import { useCart } from '../../../hooks/CartContext';
import FullPageLoader from '../../common/FullPageLoader';

export default function SwitchCartVendorModal({ isOpen, onClose }) {
  const cartContext = useCart();
  const [vendors, setVendors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchVendors = async () => {
    setIsLoading(true);

    try {
      const data = await listVendors({ limit: 1000 });
      setVendors(data.results);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Switch Cart Vendor</ModalHeader>
        <ModalCloseButton />
        <ModalBody gap='0' py='0'>
          <Stack spacing='0'>
            {isLoading && <FullPageLoader />}
            {vendors.map(vendor => (
              <HStack
                _hover={{ bg: 'gray.100' }}
                bg={
                  cartContext.selectedVendor?.id === vendor.id
                    ? 'blue.100'
                    : 'white'
                }
                mx='-6'
                px='6'
                py='3'
                key={vendor.id}
                onClick={() => {
                  cartContext.setSelectedVendor(vendor);
                  onClose();
                }}
              >
                <Stack gap='0'>
                  <Text fontSize='sm' fontWeight='medium' color='gray.700'>
                    {vendor.name}
                  </Text>

                  <Text fontSize='xs' color='gray.500'>
                    {vendor.email}
                  </Text>
                </Stack>

                <Box ml='auto'></Box>

                {cartContext.selectedVendor?.id === vendor.id && (
                  <FiCheckCircle size='20' color={'blue.500'} />
                )}
              </HStack>
            ))}
          </Stack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
