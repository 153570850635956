import { Box, Stack, Text, Tooltip } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { ChatType, listChatMessages } from '@/controllers/chat.controller';
import { useAuth } from '@/hooks/AuthContext';
import { MessageListener, useChat } from '@/hooks/ChatContext';
import useSmartToast from '@/hooks/SmartToast';
import FullPageLoader from '@/components/common/FullPageLoader';
import ChatInput from './ChatInput';

export default function ChatMessagesView() {
  const messagesEndRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const chatController = useChat();
  const { user } = useAuth();
  const toast = useSmartToast();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    if (chatController.currentConversation) {
      fetchMessages(chatController.currentConversation.type);
      const messageListener: MessageListener = {
        conversationId: chatController.currentConversation.id,
        callback: message => {
          setMessages(messages => [...messages, message]);
        },
      };

      chatController.addMessageListener(messageListener);

      return () => {
        chatController.removeMessageListener(messageListener);
      };
    }
  }, [chatController.currentConversation]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const fetchMessages = async (type: ChatType) => {
    setIsLoading(true);

    try {
      const data = await listChatMessages(
        type,
        chatController.currentConversation.id,
        {},
      );
      setMessages(data.results.reverse());
    } catch (error) {
      console.error(error);
      toast.error(error, 'Error fetching messages');
    }

    setIsLoading(false);
  };

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) {
      return;
    }

    try {
      chatController.sendMessage(
        chatController.currentConversation.id,
        chatController.currentConversation.type,
        inputMessage,
      );
      setInputMessage('');
      fetchMessages(chatController.currentConversation.type);
    } catch (error) {
      console.error(error);
      toast.error(error, 'Error sending message');
    }
  };

  return (
    <Stack spacing='0' height='full' overflowY='hidden'>
      <Stack spacing='0' overflowY='auto' h='full' gap='2'>
        {!isLoading &&
          messages.map((msg, index) => (
            <ChatMessage
              key={msg.id}
              message={msg}
              previousSender={index != 0 ? messages[index - 1]?.sender : null}
            />
          ))}
        {isLoading && <FullPageLoader />}
        {messages.length === 0 && !isLoading && (
          <Text textAlign='center' color='gray.500' fontSize='sm' p='4'>
            No messages yet
          </Text>
        )}
        <div ref={messagesEndRef} />
      </Stack>

      <ChatInput
        inputMessage={inputMessage}
        setInputMessage={setInputMessage}
        handleSendMessage={handleSendMessage}
      />
    </Stack>
  );
}

function ChatMessage({ message, previousSender }) {
  const { user } = useAuth();
  const side = message.sender.id === user?.id ? 'right' : 'left';

  const renderMessage = () => {
    const txt = message.message;
    const mentionRegex = /@\[.+\]\([a-zA-Z0-9-]+\)/g;
    const mentionInnerRegex = /@\[(.+)\]\(([a-zA-Z0-9-]+)\)/g;

    const mentions = txt.match(mentionRegex);
    const splitted = txt.split(mentionRegex);

    if (!mentions || !mentions.length) {
      return txt;
    }

    return splitted.flatMap((part, index) => {
      if (index < mentions.length) {
        const mention = mentions[index];
        const [_, name, id] = mentionInnerRegex.exec(mention);

        return [
          part,
          <Tooltip key={mention} label={name} aria-label={name} as='span'>
            <Text
              as='span'
              color={side === 'left' ? 'gray.800' : 'white'}
              fontWeight='semibold'
              cursor='pointer'
              _hover={{ textDecoration: 'underline' }}
            >
              @{name}
            </Text>
          </Tooltip>,
        ];
      } else {
        return [part];
      }
    });
  };

  return (
    <Stack
      spacing='1'
      w='full'
      px='4'
      alignItems={side === 'left' ? 'flex-start' : 'flex-end'}
    >
      {previousSender?.id !== message.sender.id && (
        <Text
          fontSize='xs'
          fontWeight='medium'
          color='gray.500'
          textAlign={side === 'left' ? 'left' : 'right'}
        >
          {side === 'left' ? message.sender.name : 'You'}
        </Text>
      )}
      <Box
        maxWidth={{ base: '90%', md: '80%' }}
        fontSize='sm'
        color={side === 'left' ? 'gray.800' : 'white'}
        bg={side === 'left' ? 'gray.100' : 'orange.500'}
        rounded='xl'
        borderTopLeftRadius={side === 'left' ? 'none' : 'xl'}
        borderTopRightRadius={side === 'left' ? 'xl' : 'none'}
        px='4'
        py='2'
        whiteSpace={'pre-wrap'}
      >
        {renderMessage()}
      </Box>
    </Stack>
  );
}
